export function scrollToElementById(id) {
  if (!id) return;

  const element = document.getElementById(id);
  const { top } = element.getBoundingClientRect();
  const offset = document.getElementById("navigation-main").clientHeight;
  let targetPosition = Math.round(top + self.pageYOffset - offset);

  window.scroll({ top: targetPosition, behavior: "smooth" });

  return new Promise((resolve, reject) => {
    const failed = setTimeout(() => {
      reject();
    }, 2000);

    const scrollHandler = () => {
      const roundOffset = Math.round(self.pageYOffset);
      if (roundOffset === targetPosition) {
        window.removeEventListener("scroll", scrollHandler);
        clearTimeout(failed);
        resolve();
      }
    };
    if (Math.round(self.pageYOffset) === targetPosition) {
      clearTimeout(failed);
      window.removeEventListener("scroll", scrollHandler);
      resolve();
    } else {
      window.addEventListener("scroll", scrollHandler);
      element.getBoundingClientRect();
    }
  });
}

<template>
  <section id="introduction" class="intro">
    <div class="intro-background-image">
      <p class="intro-title"><span>VIO</span>
        <Copyright /> on Video
      </p>
      <p class="intro-subtitle">
        Prepare for your next procedure, see the settings of worldwide renowned
        experts or share your own experiences with colleagues from around the
        globe.
      </p>
      <p class="intro-description-title">
        <span>Join the community and become a part of VIO</span>
        <Copyright /> on Video.
        <span>VIO</span>
        <Copyright /> on Video is a database of videoclips from interventions
        of various disciplines. Select your discipline and a specific
        intervention to learn how to achieve different tissue effects with
        different settings.
      </p>
    </div>
  </section>
</template>

<script>
import Copyright from '../../components/Symbols/Copyright.vue';
export default {
  name: "IntroductionSection",
  components: {
    Copyright,
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/scss";

.intro {
  height: calc(100%);
  margin-top: $navBarHeight;

  @include breakpoint(xs) {
    margin-top: $navBarHeightXS;
  }

  @include breakpoint(s) {
    margin-top: $navBarHeightS;
  }

  &-background-image {
    background-image: url("~@/assets/images/header-banner-1.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 70px 10%;

    @include breakpoint(xs) {
      background-position: unset;
      height: 70%;
    }

    @include breakpoint(s) {
      background-position: unset;
      height: 60%;
    }
  }

  &-title {
    @include ts-section-headline();
    text-align: center;
  }

  &-subtitle {
    @include ts-section-subheadline();
    box-sizing: border-box;
    text-align: center;
    margin: 50px auto;
    max-width: 1200px;

    @include breakpoint(xs) {
      margin-top: 30px;
    }

    @include breakpoint(s) {
      margin-top: 30px;
    }
  }

  &-description-title {
    @include ts-card-text();
    text-align: center;
    max-width: 890px;
    margin: 0 auto;
  }
}
</style>
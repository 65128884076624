<template>
  <div class="buttons">
    <button
      :class="mode.Mode"
      v-for="mode in modeOptions"
      :key="mode.Mode"
      @click="handleButtonChange(mode.Mode)"
    >
      {{ mode.Mode }} [{{ mode.VioCount }}]
    </button>
  </div>
</template>
<script>
import { isScatterChartMode } from "../../services/graphType";
import * as modes from "../../contstants/modes";

export default {
  name: "ModusButton",
  computed: {
    modeOptions() {
      return this.$store.state.modusButton.modes;
    },
    selectedIntervention() {
      return this.$store.state.disciplineFilter.selectedIntervention;
    },
    selectedDiscipline() {
      return this.$store.state.disciplineFilter.selectedDiscipline;
    },
    selectedMode() {
      return this.$store.state.modusButton.selectedMode;
    },
  },
  watch: {
    async selectedIntervention() {
      await this.loadButtonForSelectedDisciplineIntervention();
      await this.handleStoreActions(this.selectedMode);
    },
  },
  methods: {
    async loadButtonForSelectedDisciplineIntervention() {
      await this.$store.dispatch("loadmodusButtons", {
        discipline: this.selectedDiscipline,
        intervention: this.selectedIntervention,
      });
      await this.handleButtonChange(modes.COAG);
    },
    async handleButtonChange(mode) {
      this.unselectAllButtons();
      this.setActiveButtonBackgroundColor(mode);

      if (mode != this.selectedMode) {
        await this.handleStoreActions(mode);
      }
    },
    async handleStoreActions(mode) {
      this.setSelectedButtonToStore(mode);
      this.$store.dispatch("checkForVideos");

      if (isScatterChartMode(this.selectedMode)) {
        await this.$store.dispatch("loadChartData", {
          discipline: this.selectedDiscipline,
          intervention: this.selectedIntervention,
          mode: this.selectedMode,
        });
      }
    },
    setSelectedButtonToStore(mode) {
      this.$store.commit("setSelectedMode", mode);
    },
    setActiveButtonBackgroundColor(mode) {
      var element = document.getElementsByClassName(mode)[0];

      if (mode === modes.COAG) {
        this.setElementBackgroundColor(element, "rgb(0, 174, 239)");
      }
      if (mode === modes.CUT) {
        this.setElementBackgroundColor(element, "rgb(255, 215, 0)");
      }
      if (mode === modes.ENDOCUT_Q) {
        this.setElementBackgroundColor(element, "rgb(255, 215, 0)");
      }
      if (mode === modes.ENDOCUT_I) {
        this.setElementBackgroundColor(element, "rgb(255, 215, 0)");
      }
      if (mode === modes.APC) {
        this.setElementBackgroundColor(element, "rgb(103, 193, 140)");
      }
    },
    setElementBackgroundColor(element, color) {
      element.style.backgroundColor = color;
    },
    unselectAllButtons() {
      this.modeOptions.forEach((mode) => {
        document.getElementsByClassName(mode.Mode)[0].style.backgroundColor =
          "rgb(153, 153, 153)";
      });
    },
  },
  async mounted() {
    await this.loadButtonForSelectedDisciplineIntervention();
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss";

button {
  width: 220px;
  justify-content: flex-start;
  height: 45px;
  border: none;
  color: black;
  text-align: center;
  font-size: 20px;
  background-repeat: no-repeat;
  cursor: pointer;

  @include breakpoint(xs) {
    width: 250px;
    height: 50px;
    margin-bottom: 10%;
    border: none;
    color: black;
    text-align: center;
    font-size: 20px;
    background-repeat: no-repeat;
  }
}

.COAG {
  margin-right: 1%;
  background-color: rgb(153, 153, 153);
}

.CUT {
  margin-right: 1%;
  background-color: rgb(153, 153, 153);

  @include breakpoint(xs) {
    margin-bottom: 10%;
  }
}

[class="endoCUT Q"] {
  margin-right: 1%;
  background-color: rgb(153, 153, 153);

  @include breakpoint(xs) {
    margin-bottom: 10%;
  }
}

[class="endoCUT I"] {
  background-color: rgb(153, 153, 153);
}

.APC {
  background-color: rgb(153, 153, 153);
  margin-right: 1%;

  @include breakpoint(xs) {
    margin-bottom: 10%;
  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 40px;

  @include breakpoint(xs) {
    justify-content: space-evenly;
    flex-direction: column;
  }
}
</style>
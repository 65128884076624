<template>
  <div id="app">
    <DisclaimerPopup />
    <nav>
      <NavigationBar />
    </nav>
    <main>
      <router-view />
    </main>
  </div>
</template>

<script>
import NavigationBar from "@/components/Navigation/Index";
import DisclaimerPopup from "./components/DisclaimerPopup.vue";
export default {
  components: {
    NavigationBar,
    DisclaimerPopup,
  },
  mounted() {
    this.$store.commit("loadPlaylistVideos");
  },
};
</script>

<style lang="scss">
@import "@/assets/scss";
@import "@/assets/scss/base";

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>

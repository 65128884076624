<template>
  <div class="google-maps">
    <gmap-map :zoom="2" :center="center" class="map">
      <gmap-marker
        :key="index"
        v-for="(marker, index) in markerCollection"
        :position="marker"
        @click="addVideos(marker)"
      ></gmap-marker>
    </gmap-map>
    <div v-if="mapVideos.length > 0" class="video-sgement-scollbox">
      <ScrollableVideoSegment :videos="mapVideos" :fullscreen="true" />
    </div>
  </div>
</template>
 
<script>
import ScrollableVideoSegment from "../../../components/ScrollableVideoSegement.vue";
export default {
  name: "AddGoogleMap",
  components: {
    ScrollableVideoSegment,
  },
  props: {
    discipline: {
      type: String,
      require: true,
    },
    intervention: {
      type: String,
      require: true,
    },
    mode: {
      type: String,
      require: true,
    },
  },
  computed: {
    markerCollection() {
      return this.$store.state.maps.marker;
    },
    mapVideos() {
      return this.$store.state.maps.videos;
    },
  },
  data() {
    return {
      center: { lat: 10, lng: 10 },
    };
  },
  methods: {
    async addVideos(marker) {
      var parameter = {
        discipline: this.discipline,
        intervention: this.intervention,
        mode: this.mode,
        city: marker.city,
        country: marker.country,
      };
      await this.$store.dispatch("loadMapVideos", parameter);
    },
  },
  async mounted() {
    var options = {
      discipline: this.discipline,
      intervention: this.intervention,
      mode: this.mode,
    };
    await this.$store.dispatch("loadMarker", options);
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/scss";

.google-maps {
  display: flex;
  flex-direction: row;

  @include breakpoint(xs) {
    flex-direction: column;
  }
  @include breakpoint(s) {
    flex-direction: column;
  }

  .map {
    width: 100%;
    height: 600px;

    @include breakpoint(xs) {
      height: 500px;
      margin-bottom: 50px;
    }
    @include breakpoint(s) {
      margin-bottom: 50px;
    }
  }
}
.video-sgement-scollbox {
  overflow-y: auto;
  height: 600px;

  @include breakpoint(xs) {
    height: 500px;
    overflow-x: hidden;
  }
}
</style>
<template>
  <div v-if="!accepted" class="disclaimer">
    <div class="disclaimer-content">
      <p class="disclaimer-content-headline">Disclaimer</p>
      <p class="disclaimer-content-text">The content on this site is intended for healthcare professionals only. It may
        contain information about Erbe
        products that potentially have not been approved yet in your region or country. The products shown are not
        available for purchase by the general public.</p>
      <p class="disclaimer-content-text">Always follow the directions for use attached to each Erbe device.</p>
      <p class="disclaimer-content-text">If you agree to the above and the <a
          href="https://erbe-med.com/fileadmin/user_upload/documents/PDF/Terms_of_use_EN.pdf" target="_blank">terms of
          use</a>, please continue.</p>
      <div class="button-section">
        <button class="button-section-continue" @click="acceptDisclaimer()">Accept</button>
        <a href="https://en.erbe-med.com/en-en/global-home" target="_blank" class="button-section-cancel">Cancel</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      accepted: false,
    };
  },
  methods: {
    acceptDisclaimer() {
      this.accepted = true;
      localStorage.setItem("disclaimerAccepted", this.accepted);
    },
  },
  mounted() {
    var storedAccept = localStorage.getItem("disclaimerAccepted") ?? false;
    this.accepted = storedAccept;
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss";

.disclaimer {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);

  &-content {
    width: 50%;
    height: fit-content;
    background-color: white;
    opacity: unset;
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 30px;

    @include breakpoint(xs) {
      width: 100%;
    }

    &-headline {
      @include ts-section-subheadline();
    }

    &-text {
      margin-top: 20px;
      @include ts-card-text();
      font-family: $font_nettoLight;
    }

    a {
      text-decoration: underline;
      color: $color_erbeBlue;
    }

    .button-section {
      display: flex;
      margin-top: 30px;
      align-items: center;
      justify-content: space-between;
      @include ts-card-text();


      &-continue {
        background-color: $color_erbeBlue;
        color: white;
        padding: 12px 20px;
        border-radius: 3px;
        cursor: pointer;
        @include ts-card-text();

      }
    }
  }
}
</style>
<template>
  <div class="paged-video-segment">
    <button class="prev-button" v-show="showPrevButton">
      <i @click="onPageClick(-1)" id="arrow" class="fas fa-arrow-left fa-3x"></i>
    </button>
    <div class="videos">
      <VideoCard v-for="video in videosToDisplay" :key="video.VideoId" :video="video" />
    </div>
    <button class="next-button" v-show="showNextButton">
      <i @click="onPageClick(+1)" id="arrow" class="fas fa-arrow-right fa-3x"></i>
    </button>
  </div>
</template>

<script>
import VideoCard from "./VideoCard.vue";

export default {
  components: {
    VideoCard,
  },
  props: {
    videos: {
      type: Array,
      require: true,
    },
    fullscreen: {
      type: Boolean,
      require: true,
    },
  },
  watch: {
    videos() {
      this.calculateVideosToDisplay();
    },
  },
  data() {
    return {
      videosToDisplay: [],
      page: 0,
      numberOfVideosToDisplay: 4,
      showNextButton: false,
    };
  },
  computed: {
    showPrevButton() {
      return this.page > 0;
    },
    selectedMode() {
      return this.$store.state.modusButton.selectedMode;
    },
  },
  methods: {
    onPageClick(operator) {
      this.page = this.page + operator;
      this.calculateVideosToDisplay();
    },
    calculateVideosToDisplay() {
      this.videosToDisplay = [];
      for (
        let index = this.page * this.numberOfVideosToDisplay;
        index < this.numberOfVideosToDisplay * (this.page + 1);
        index++
      ) {
        var element = this.videos[index];
        if (element !== undefined) {
          this.videosToDisplay.push(element);
        }
        this.toggleNextButton(index);
      }
    },
    toggleNextButton(index) {
      this.showNextButton = true;
      var nextElement = this.videos[index + 1];
      if (nextElement === undefined) {
        this.showNextButton = false;
      }
    },
  },
  mounted() {
    this.calculateVideosToDisplay();
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss";

.paged-video-segment {
  display: grid;
  grid-template-columns: 50px 1fr 50px;

  @media (max-width: 1024px) {
    grid-template-columns: unset;
    grid-template-rows: 50px 1fr 50px;
  }
}

.videos {
  display: flex;
  grid-column-start: 2;
  justify-content: center;
  flex-wrap: wrap;

  &>* {
    margin: 10px;
  }

  @media (max-width: 1024px) {
    grid-column-start: unset;
    grid-row-start: 2;
    flex-direction: column;
    align-items: center;
  }
}

.prev-button {
  grid-column-start: 1;

  @media (max-width: 1024px) {
    grid-column-start: unset;
    grid-row-start: 1;
  }
}

.next-button {
  grid-column-start: 3;

  @media (max-width: 1024px) {
    grid-column-start: unset;
    grid-row-start: 3;
  }
}

#arrow {
  color: white;
  cursor: pointer;
}
</style>
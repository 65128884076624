export function initializeAnalytics() {
  const { VUE_APP_MATOMO_SITEID } = process.env;
  if (!VUE_APP_MATOMO_SITEID) {
    return;
  }
  var _paq = window._paq = window._paq || [];
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  (function() {
    var u="//www.erbe-med.com/piwik/";
    _paq.push(['setTrackerUrl', u+'matomo.php']);
    _paq.push(['setSiteId', VUE_APP_MATOMO_SITEID]);
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.type='text/javascript'; g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
  })();
}

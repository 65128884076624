<template>
  <CustomModal :modalOpen="modalOpen" @toggleModal="toggleModal()">
    <div class="playlist">
      <p class="playlist-headline">Your Playlist</p>
      <div class="video-segment-scrollbox">
        <ScrollableVideoSegment :fullscreen="true" :videos="playlist" />
      </div>
      <p v-if="playlistLength === 0" class="info-text">
        Select a video and add it to your playlist by clicking the heart-shaped
        icon linked to it. To search for videos, please select your discipline
        and the intervention below first.
      </p>
      <button
        v-if="playlistLength > 0"
        @click="clearPlaylist()"
        class="clear-playlist-button"
      >
        Clear Playlist
      </button>
    </div>
  </CustomModal>
</template>

<script>
import CustomModal from "../CustomModal.vue";
import ScrollableVideoSegment from "../ScrollableVideoSegement.vue";
export default {
  props: {
    modalOpen: {
      type: Boolean,
      require: true,
    },
  },
  computed: {
    playlist() {
      return this.$store.state.playlist.videos;
    },
    playlistLength() {
      return this.$store.state.playlist.videos.length;
    },
  },
  methods: {
    toggleModal() {
      this.$emit("toggleModal");
    },
    clearPlaylist() {
      this.$store.commit("clearPlaylist");
    },
  },
  components: {
    CustomModal,
    ScrollableVideoSegment,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss";
.playlist {
  display: flex;
  flex-direction: column;
  height: 100%;

  &-headline {
    @include ts-section-headline();
    margin-bottom: 30px;
  }

  .video-segment-scrollbox {
    overflow-y: scroll;
    margin-bottom: 30px;
  }

  .clear-playlist-button {
    height: 35px;
    padding: 10px;
    background-color: rgb(0, 174, 239);
    color: white;
    border-radius: 2px;
    cursor: pointer;
    width: 150px;
    align-self: flex-end;
    margin-top: auto;

    @include breakpoint(xs) {
      align-self: center;
    }
  }

  .info-text {
    text-align: center;
    font-size: 30px;
    max-width: 700px;
    margin: auto;
  }
}
</style>
<template>
  <section class="graph-section" id="graph-section">
    <DisciplineInterventionFilter />
    <div class="background" v-if="isInterventionSelected">
      <ModusButton />
      <div class="graph-grid">
        <div class="charts">
          <ScatterChart @circleClick="(effect, submode) => onCircleClick(effect, submode)" v-if="showScatterChart"
            :isShowAll="false" :selectedMode="selectedMode" :matrixChartData="{
              matrixData: scatterChartData,
            }" />
          <BarChart @barChartChange="(mode, effect, duration, interval) =>
              onBarChartChange(mode, effect, duration, interval)
            " v-if="showBarChart" :discipline="selectedDiscipline" :intervention="selectedIntervention"
            :selectedMode="selectedMode" />
          <GoogleMaps v-if="showMap" :discipline="selectedDiscipline" :intervention="selectedIntervention"
            :mode="selectedMode" />
        </div>
        <button class="google-maps-button" v-if="showMapsButton" @click="toggleMap">
          <img v-if="showMap" src="@/assets/images/mapsicon_list.png" />
          <img v-if="!showMap" src="@/assets/images/globe.svg" />
        </button>
      </div>
      <p class="no-data-text" v-if="selectedModeVideoCount === 0">
        No Data for selected mode
      </p>
      <p v-if="showBarChart" class="button-headline">TOP 5</p>
      <PagedVideoSegment v-if="chartVideos.length > 0" :videos="chartVideos" :fullscreen="true" />
    </div>
  </section>
</template>

<script>
import ModusButton from "./ModusButtons.vue";
import DisciplineInterventionFilter from "./DisciplineInterventionFilter.vue";
import ScatterChart from "./Charts/ScatterChart.vue";
import BarChart from "./Charts/BarChart.vue";
import { isScatterChartMode, isBarChartMode } from "../../services/graphType";
import PagedVideoSegment from "../../components/PagedVideoSegment.vue";
import GoogleMaps from "./Charts/GoogleMaps.vue";

export default {
  name: "GraphSection",
  components: {
    ModusButton,
    DisciplineInterventionFilter,
    ScatterChart,
    BarChart,
    PagedVideoSegment,
    GoogleMaps,
  },
  data() {
    return {
      showMap: false,
      settings: {
        suppressScrollY: false,
        suppressScrollX: false,
        wheelPropagation: false,
      },
    };
  },
  methods: {
    onCircleClick(effect, submode) {
      var options = {
        discipline: this.selectedDiscipline,
        intervention: this.selectedIntervention,
        mode: this.selectedMode,
        submode: submode,
        effect: effect,
      };

      this.$store.dispatch("loadScatterGraphVideos", options);
    },

    onBarChartChange(effect, duration, interval) {
      var options = {
        discipline: this.selectedDiscipline,
        intervention: this.selectedIntervention,
        mode: this.selectedMode,
        duration: duration,
        effect: effect,
        interval: interval,
      };
      this.$store.dispatch("loadBarChartVideos", options);
    },
    toggleMap() {
      this.showMap = !this.showMap;
      this.$store.commit("removeChartVideos");
    },
    resetGraphSection() {
      this.showMap = false;
      this.$store.commit("resetMarker");
      this.$store.commit("removeChartVideos");
      this.$store.commit("resetMapVideos");
    },
  },
  watch: {
    selectedDiscipline() {
      this.resetGraphSection();
    },
    selectedIntervention() {
      this.resetGraphSection();
    },
    selectedMode() {
      this.resetGraphSection();
    },
  },
  computed: {
    showScatterChart() {
      return (
        !this.scatterChartDataLoading &&
        isScatterChartMode(this.selectedMode) &&
        this.selectedModeVideoCount > 0 &&
        !this.showMap
      );
    },
    showBarChart() {
      return (
        isBarChartMode(this.selectedMode) &&
        this.selectedModeVideoCount > 0 &&
        !this.showMap
      );
    },
    showMapsButton() {
      return (
        this.selectedModeVideoCount > 0 && isScatterChartMode(this.selectedMode)
      );
    },
    isInterventionSelected() {
      return this.$store.state.disciplineFilter.selectedIntervention != "";
    },

    selectedModeVideoCount() {
      return this.$store.state.modusButton.selectedModeVideoCount;
    },
    modes() {
      return this.$store.state.modusButton.modes;
    },
    selectedDiscipline() {
      return this.$store.state.disciplineFilter.selectedDiscipline;
    },
    selectedIntervention() {
      return this.$store.state.disciplineFilter.selectedIntervention;
    },
    selectedMode() {
      return this.$store.state.modusButton.selectedMode;
    },
    scatterChartData() {
      return this.$store.state.charts.scatterChartData;
    },
    scatterChartDataLoading() {
      return this.$store.state.charts.scatterChartLoading;
    },
    chartVideos() {
      return this.$store.state.charts.chartVideos;
    },
  },
  updated() {
    var element = document.getElementsByClassName("charts")[0];

    if (this.showScatterChart) {
      element.style.backgroundColor = "#19222b";
    } else {
      element.style.backgroundColor = "unset";
    }
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss";

.charts {
  overflow-x: auto;
  overflow-y: hidden;

  @include breakpoint(xs) {
    width: 65vw;
    margin: 0 auto;
  }

  @include breakpoint(s) {
    width: 70vw;
    margin: 0 auto;
  }
}

.graph-grid {
  display: grid;
  grid-template-columns: 1fr min-content;
  align-items: end;

  @include breakpoint(xs) {
    grid-template-columns: 1fr;
  }

  @include breakpoint(s) {
    grid-template-columns: 1fr;
  }
}

.videos-title {
  color: #d3d3d3;
  font-family: "Netto Regular";
  font-size: 30px;
  padding: 15px;
}

.gmap-video-section {
  display: flex;
  grid-column-start: 3;
}

.world_videos {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: auto;
  width: 600px;
  height: 600px;
}

.no-data-text {
  @include ts-section-headline();
  color: white;
  text-align: center;
}

.loading-text {
  height: 500px;
  width: 100%;
}

.graph-section {
  background-color: rgb(0, 174, 239);
  padding: 70px 10%;

  @include breakpoint(xs) {
    padding: 70px 0;
  }
}

.background {
  background-color: rgb(47, 43, 43);
  margin-top: 50px;
  padding: 40px;
}

.google-maps-button {
  display: flex;
  border-radius: 10px;
  height: 55px;
  width: 55px;
  margin-left: 15px;
  grid-column-start: 2;

  @include breakpoint(xs) {
    grid-column-start: unset;
    margin-left: unset;
    margin: 20px auto;
  }

  @include breakpoint(s) {
    grid-column-start: unset;
    margin-left: unset;
    margin: 50px auto;
  }
}

.google-maps-button img {
  display: block;
  width: 100%;
  height: auto;
  cursor: pointer;
}

.button-headline {
  font-family: $font_nettoRegular;
  color: white;
  font-size: 30px;
  text-align: center;
  margin-top: 10px;
}
</style>
import * as modes from "../contstants/modes";

function isScatterChartMode(mode) {
  if ([modes.COAG, modes.CUT, modes.APC].indexOf(mode) >= 0) {
    return true;
  }
  return false;
}

function isBarChartMode(mode) {
  if ([modes.ENDOCUT_Q, modes.ENDOCUT_I].indexOf(mode) >= 0) {
    return true;
  }
  return false;
}

export { isScatterChartMode, isBarChartMode }
<template>
  <div class="video-card" :class="'video-card-' + video.ParentMode">
    <img @click="handleAddPlaylistButtonClick()" class="heart-unclicked" :src="isVideoInPlaylist()
      ? '/svg/heart-clicked.svg'
      : '/svg/heart-unclicked.svg'
      " />
    <iframe class="vimeo-video" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
      :src="`https://player.vimeo.com/video/${video.VideoId}`" />
    <div class="video-info" :class="'video-info-' + video.ParentMode">
      <p v-if="video.Title" class="video-headline">{{ video.Title }}:</p>
      <p v-if="video.Effect && video.Duration && video.Interval" class="video-headline">
        E{{ video.Effect }}-D{{ video.Duration }}-I{{ video.Interval }}
      </p>
      <span>{{ video.Person.Title }} {{ video.Person.Name }}
        {{ video.Person.SurName }}, {{ video.Hospital.Name }},
        {{ video.Procedure }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    video: {
      type: Object,
      require: true,
    },
  },
  computed: {
    playlist() {
      return this.$store.state.playlist.videos;
    },
  },
  methods: {
    handleAddPlaylistButtonClick() {
      this.$store.commit("handleAddPlaylistButtonClick", this.video);
    },
    isVideoInPlaylist() {
      var videoIsIn = false;

      this.playlist.map((playlistVideo) => {
        if (playlistVideo.VideoId === this.video.VideoId) {
          videoIsIn = true;
        }
      });

      return videoIsIn;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss";


.video-card {
  font-family: $font_nettoRegular;
  position: relative;
  background-color: rgb(0, 174, 239);


  &-COAG {
    background-color: rgb(0, 174, 239);
  }

  &-CUT {
    background-color: rgb(255, 215, 0);
  }

  &-APC {
    background-color: rgb(103, 193, 140);
  }

  &-endoCUT {
    background-color: rgb(255, 215, 0);
  }

  .heart-unclicked {
    height: 25px;
    position: absolute;
    top: 15px;
    left: 15px;
    cursor: pointer;
  }

  .vimeo-video {
    height: 168.72px;
    width: calc(168.72px * 1.778);

    @media (max-width: 490px) {
      height: 132.17px;
      width: calc(132.17px * 1.778);
    }
  }

  .video-info {
    width: 300px;
    margin-top: -3px;
    padding: 10px;
    color: white;


    &-COAG,
    &-APC {
      color: white;
    }

    &-endoCUT,
    &-CUT {
      color: black;
    }

    @media (max-width: 490px) {
      width: 235px;
    }

    .video-headline {
      margin-bottom: 5px;
      font-weight: bold;
    }
  }
}
</style>
<template>
  <section class="clinical-application-team">
    <p class="title-text">Clinical Application Team</p>
    <img class="team-logo" src="@/assets/images/team.jpg" alt="Erbe Team" />
    <div class="clinical-application-body">
      <div class="description">
        <div class="texts">
          <p class="text">
            We are a young, international team of experts dedicated to the application of our products in various
            disciplines.
          </p>
          <p class="text2">
            Our aim is to support and advise users regarding the daily challenges of surgical procedures.

            To this end, we maintain a global network and are in daily contact with our partners around the world to gain
            and pass on knowledge, in part through practical workshops.
          </p>
        </div>
        <div class="contact-field">
          <p class="contact">
            <a href="mailto:clinical-application@erbe-med.com" class="contact-link">
              <img class="arrow-image"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAAAwklEQVQ4ja3V0Q2CMBDG8X8b32ETGcUNdAUnUCfAEWQDN9ARcAIZASY4c6T1wUChtV9yDw30l/RKDiMi+BhjKqAkLp2IdN8dDlTkqcvEuqozWg5s/8B8nf1pdxkwrV5BC1SRPZtLoXdgIzc1wCvwvIwF98AthMaCmjqEpoBBdPOzHtytr0kP3IF3CCyAx0qwmfpCUo98dNg2B6jYYQpLAS8hjIkeLuW09EJqD+fSWzdpcmQQkTb/+Mo0YEdMK+8vAPgArESbP3d8qjMAAAAASUVORK5CYII=" />
              clinical-application@erbe-med.com
            </a>
          </p>
          <p class="contact">
            <a class="contact-link">
              <img class="arrow-image"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAAAwklEQVQ4ja3V0Q2CMBDG8X8b32ETGcUNdAUnUCfAEWQDN9ARcAIZASY4c6T1wUChtV9yDw30l/RKDiMi+BhjKqAkLp2IdN8dDlTkqcvEuqozWg5s/8B8nf1pdxkwrV5BC1SRPZtLoXdgIzc1wCvwvIwF98AthMaCmjqEpoBBdPOzHtytr0kP3IF3CCyAx0qwmfpCUo98dNg2B6jYYQpLAS8hjIkeLuW09EJqD+fSWzdpcmQQkTb/+Mo0YEdMK+8vAPgArESbP3d8qjMAAAAASUVORK5CYII=" />
              +49 (0) 7071–755-0
            </a>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "ClinicalApplicationTeam",
};
</script>
<style scoped lang="scss">
@import "@/assets/scss";

.clinical-application-team {
  background-color: rgb(171, 221, 219);
  padding: 50px 15%;
  display: grid;
  column-gap: 30px;
  grid-template:
    "header header" 100px
    "img1 description" auto
    "img1 description" auto / 1fr 2fr;

  @include breakpoint(s) {
    row-gap: 30px;
    grid-template:
      "header" auto
      "img1" auto
      "description" auto / 1fr;
  }

  @include breakpoint(xs) {
    row-gap: 30px;
    grid-template:
      "header" auto
      "img1" auto
      "description" auto / 1fr;
  }

  .title-text {
    @include ts-section-headline();
    grid-area: header;
    text-align: center;
  }

  .team-logo {
    grid-area: img1;
    max-width: 100%;
  }

  .clinical-application-body {
    grid-area: description;

    .description {
      .text {
        @include ts-card-text();
      }

      .text2 {
        margin-top: 10px;
        @include ts-card-text();
      }
    }

    .contact-field {
      margin-top: 30px;

      .contact {
        .contact-link {
          @include link-item-text();
        }
      }
    }
  }
}
</style>
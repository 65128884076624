<template>
  <div class="navigation">
    <nav id="navigation-main" class="navigation-main" ref="main">
      <a href="https://en.erbe-med.com/en-en" target="_blank">
        <img class="navigation-main-logo" src="@/assets/images/Erbe_logo.png" alt="Erbe company logo" />
      </a>
      <div class="global_flex1"></div>
      <button class="navigation-main-toggle" @click="toggleMenu()">
        <img :src="menuIconSrc" />
      </button>
      <ul :class="`navigation-main-items navigation-main-items--${isMenuVisible ? 'open' : 'closed'
        }`">
        <li class="navigation-main-item">
          <div @click="togglePlaylistModal()" class="navigation-main-item-playlist">
            <img src="images/heart.png" class="navigation-main-item-playlist-image" />
            <span class="navigation-main-item-playlist-text">PLAYLIST [{{ playlistVideoCount }}]</span>
          </div>
        </li>
        <!-- <li class="navigation-main-item">
          <span @click="toggleWhatIsModal()" class="navigation-main-item-text"
            >WHAT IS VIO ON VIDEO</span
          >
        </li> -->
        <li @click="scrollTo('upload-formular')" class="navigation-main-item">
          <span class="navigation-main-item-text">SUBMIT A VIDEO</span>
        </li>
        <!-- <li class="navigation-main-item">
          <span @click="toggleFaqModal()" class="navigation-main-item-text"
            >FAQ</span
          >
        </li> -->
      </ul>
    </nav>
    <PlaylistModal :modalOpen="playlistModalOpen" @toggleModal="togglePlaylistModal" />
    <WhatIsVioOnVideoModal :modalOpen="whatIsModalOpen" @toggleModal="toggleWhatIsModal" />
    <FaqModal :modalOpen="faqModalOpen" @toggleModal="toggleFaqModal" />
  </div>
</template>

<script>
import PlaylistModal from "../Modals/PlaylistModal.vue";
import WhatIsVioOnVideoModal from "../Modals/WhatIsVioOnVideoModal.vue";
import FaqModal from "../Modals/FaqModal.vue";
import { scrollToElementById } from "../../services/scroll";

export default {
  components: {
    PlaylistModal,
    WhatIsVioOnVideoModal,
    FaqModal,
  },
  data() {
    return {
      playlistModalOpen: false,
      faqModalOpen: false,
      whatIsModalOpen: false,
      isMenuVisible: false,
    };
  },
  watch: {
    isMenuVisible() {
      document.body.style.overflow = this.isMenuVisible ? "hidden" : "visible";
    },
  },
  computed: {
    menuIconSrc() {
      return this.isMenuVisible
        ? "/svg/nav_menu_open.svg"
        : "/svg/nav_menu_closed.svg";
    },
    playlistVideoCount() {
      return this.$store.state.playlist.videos.length;
    },
  },
  methods: {
    scrollTo(id) {
      scrollToElementById(id);
    },
    toggleMenu() {
      this.isMenuVisible = !this.isMenuVisible;
    },
    togglePlaylistModal() {
      this.playlistModalOpen = !this.playlistModalOpen;
    },
    toggleFaqModal() {
      this.faqModalOpen = !this.faqModalOpen;
    },
    toggleWhatIsModal() {
      this.whatIsModalOpen = !this.whatIsModalOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/";

.navigation {
  @include flex-v(stretch, stretch);

  &-main {
    height: $navBarHeight;

    @include breakpoint(xs) {
      height: $navBarHeightXS;
    }

    @include breakpoint(s) {
      height: $navBarHeightS;
    }
  }

  &-main {
    background: black;
    @include flex-h(space-between, center);
    @include padding-h(60px);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 997;

    @include breakpoint(xs) {
      @include padding-h(30px);
    }

    @include breakpoint(s) {
      @include padding-h(30px);
    }

    &-logo {
      height: 70px;
      display: block;

      @include breakpoint(xs) {
        height: 30px;
      }

      @include breakpoint(s) {
        height: 50px;
      }
    }

    &-items {
      @include flex-h(center, center);

      @mixin itemsStyle {
        @include flex-v(center, center);
        position: fixed;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.78);
        text-align: center;

        &--open {
          display: block;
        }

        &--closed {
          display: none;
        }
      }

      @include breakpoint(xs) {
        @include itemsStyle();
        top: $navBarHeightXS;
      }

      @include breakpoint(s) {
        @include itemsStyle();
        top: $navBarHeightS;
      }
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: center;

      @include breakpoint(xs) {
        margin-top: 30px;
      }

      @include breakpoint(s) {
        margin-top: 30px;
      }

      &-playlist {
        display: inherit;
        justify-content: inherit;
        align-items: inherit;
        margin-right: 25px;
        cursor: pointer;

        @include breakpoint(xs) {
          margin-right: 0;
        }

        @include breakpoint(s) {
          margin-right: 0;
        }

        &-text {
          font-family: $font_nettoBold;
          margin-left: 25px;
          @include text(20px, 24px, 0px);
          color: #00aeef;
          display: block;

          @include breakpoint(xs) {
            margin-left: 0;
            @include text(18px, 28px, 0px);
          }

          @include breakpoint(s) {
            margin-left: 0;
            @include text(24px, 30px, 0px);
          }
        }

        &-image {
          height: 16px;
          width: 16px;
          margin-right: -15px;

          @include breakpoint(xs) {
            margin-right: 5px;
            height: 15px;
            width: 15px;
          }

          @include breakpoint(s) {
            margin-right: 5px;
          }
        }
      }

      &-text {
        cursor: pointer;
        font-family: $font_nettoBold;
        @include margin-h(25px);
        @include text(20px, 24px, 0px);
        color: #00aeef;
        display: block;

        @include breakpoint(xs) {
          @include margin-h(0);
          @include text(18px, 28px, 0px);
        }

        @include breakpoint(s) {
          @include margin-h(0);
          @include text(24px, 30px, 0px);
        }
      }
    }

    &-toggle {
      display: none;

      @mixin toggleStyle {
        @include flex-h(center, center);
        padding: 8px;
        margin: -8px;
      }

      @include breakpoint(xs) {
        @include toggleStyle();
      }

      @include breakpoint(s) {
        @include toggleStyle();
      }
    }
  }
}
</style>

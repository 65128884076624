<template>
  <div class="select" tabindex="0" @blur="setOpen(false)">
    <div class="selected" :class="{ open: open, isDisabled: isDisabled }" @click="setOpen(!open)">
      <p>{{ selected }}</p>
      <img class="selected-arrow" :class="{ 'arrow-rotate': open }" src="/svg/arrow.svg" />
    </div>
    <div class="options" :class="{ selectHide: !open }">
      <div v-for="(option, i) of options" :key="i" @click="onOptionClick(option)">
        {{ option }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomSelect",
  props: {
    options: {
      type: Array,
      required: true,
    },
    default: {
      type: String,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selected: this.default,
      open: false,
    };
  },
  watch: {
    default() {
      this.selected = this.default;
    },
    isDisabled() {
      if (this.isDisabled === true) {
        this.selected = this.default;
      }
    },
  },
  methods: {
    setOpen(openState) {
      if (!this.isDisabled) {
        this.open = openState;
      }
    },
    onOptionClick(option) {
      this.selected = option;
      this.setOpen(false);
      this.$emit("input", option);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss";

.select {
  @include ts-select-text();
  position: relative;
  text-align: center;
  outline: none;

  .selected {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    background-color: rgb(35, 31, 32);
    border-radius: 10px;
    color: white;
    cursor: pointer;
    user-select: none;

    &-arrow {
      height: 20px;
    }

    .arrow-rotate {
      height: 20px;
      transform: rotate(90deg);
    }
  }

  .open {
    border-radius: 10px 10px 0 0;
    padding-bottom: 15px;
  }

  .isDisabled {
    background-color: rgb(153, 153, 153);
  }

  .options {
    color: #fff;
    border-radius: 0px 0px 10px 10px;
    overflow: hidden;
    position: absolute;
    background-color: rgb(35, 31, 32);
    left: 0;
    right: 0;
    z-index: 1;

    div {
      color: #fff;
      cursor: pointer;
      user-select: none;

      &:hover {
        background-color: rgb(76, 76, 76);
      }
    }
  }

  .selectHide {
    display: none;
  }
}
</style>

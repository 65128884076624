<template>
  <div class="modal">
    <transition name="pop" appear>
      <div v-if="modalOpen" class="modal-dialog">
        <img @click="toggleModal" class="xmark" src="/svg/x-solid.svg" />
        <slot></slot>
      </div>
    </transition>
    <div v-if="modalOpen" @click="toggleModal" class="modal-mask"></div>
  </div>
</template>

<script>
export default {
  props: {
    modalOpen: {
      type: Boolean,
      require: true,
    },
    headline: {
      type: String,
      require: true,
    },
  },
  methods: {
    toggleModal() {
      this.$emit("toggleModal");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss";

.modal {
  font-family: $font_nettoRegular;

  &-dialog {
    z-index: 999;
    background-color: white;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 80%;
    width: 80vw;
    margin: auto;
    padding: 50px;

    @include breakpoint(xs) {
      height: 100%;
      width: 100%;
    }

    .xmark {
      height: 20px;
      width: 20px;
      cursor: pointer;
      position: absolute;
      top: 5px;
      right: 5px;

      @include breakpoint(xs) {
        top: 10px;
        right: 10px;
      }
    }
  }

  &-mask {
    z-index: 998;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: black;
    opacity: 0.6;
  }
}

.pop-enter-active,
.pop-leave-active {
  transition: transform 0.4s cubic-bezier(0.5, 0, 0.5, 1), opacity 0.4s linear;
}

.pop-enter,
.pop-leave-to {
  opacity: 0;
  transform: scale(0) translateY(-50%);
}
</style>
<template>
  <div class="discipline-intervention-filter">
    <p class="discipline-intervention-filter-headline">
      Choose your discipline and intervention
    </p>
    <p class="discipline-intervention-filter-subheadline">
      <span>to see performance of VIO</span>
      <Copyright /> 3 and APC 3 in a range of settings
    </p>
    <div class="discipline-intervention-filter-selector">
      <CustomSelect class="select" :options="disciplineOptions"
        :default="selectedDiscipline != '' ? selectedDiscipline : '1: Choose Discipline'"
        @input="(discipline) => setDiscipline(discipline)" />
      <CustomSelect class="select" :options="interventionOptions" default="2: Choose Intervention"
        :isDisabled="interventionOptions.length === 0" @input="(intervention) => setIntervention(intervention)" />
    </div>
  </div>
</template>
<script>
import CustomSelect from "@/components/CustomSelect.vue";
import Copyright from "../../components/Symbols/Copyright.vue"
import { scrollToElementById } from "../../services/scroll";

export default {
  name: "DisciplineInterventionFilter",
  components: {
    CustomSelect,
    Copyright
  },
  watch: {
    '$route.query.discipline': function () {
      this.setDisciplineOutOfUrlQuery();
    },
    deep: true,
  },
  computed: {
    disciplineOptions() {
      return this.$store.state.disciplineFilter.disciplineOptions;
    },
    interventionOptions() {
      return this.$store.state.disciplineFilter.interventionOptions;
    },
    selectedDiscipline() {
      return this.$store.state.disciplineFilter.selectedDiscipline;
    },
  },
  methods: {
    setDiscipline(selectedDiscipline) {
      this.$store.commit("setSelectedDiscipline", selectedDiscipline);
      this.$store.dispatch("getfilteredInterventions");
    },
    setIntervention(selectedIntervention) {
      this.$store.commit("setSelectedIntervention", selectedIntervention);
    },
    setDisciplineOutOfUrlQuery() {
      var queryDiscipline = this.$route.query.discipline;
      queryDiscipline = queryDiscipline[0].toUpperCase() + queryDiscipline.slice(1);

      if (queryDiscipline) {
        this.$store.commit("setSelectedDiscipline", queryDiscipline);
        this.$store.dispatch("getfilteredInterventions");
        scrollToElementById("graph-section");
      }
    },
  },
  mounted() {
    this.$store.dispatch("loadDisciplineOptions");
    this.setDisciplineOutOfUrlQuery();
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss";

.discipline-intervention-filter {
  &-headline {
    @include ts-section-headline();
    text-align: center;
    margin-bottom: 20px;
  }

  &-subheadline {
    @include ts-section-subheadline();
    text-align: center;
    margin-bottom: 50px;

    @include breakpoint(xs) {
      padding: 0 10%;
    }
  }

  &-selector {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @include breakpoint(xs) {
      flex-direction: column;
    }

    .select {
      min-width: 380px;
      height: 50px;

      @include breakpoint(xs) {
        min-width: 310px;
      }
    }

    .select:first-child {
      margin-right: 5px;

      @include breakpoint(xs) {
        margin-right: unset;
      }
    }

    .select:last-child {
      margin-left: 5px;

      @include breakpoint(xs) {
        margin-left: unset;
        margin-top: 20px;
      }
    }
  }
}
</style>
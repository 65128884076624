<template>
  <div class="chart">
    <svg
      id="svgMatrixGraph"
      :width="600"
      :height="svgHeight"
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      class="graph"
      aria-labelledby="title"
      role="img"
    ></svg>
  </div>
</template>

<script>
export default {
  props: {
    selectedMode: {
      type: String,
      required: true,
    },
    matrixChartData: {
      type: Object,
      required: true,
    },
    isShowAll: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      svgHeight: 400,
      WIDTH: 1075,
      YAXISWIDTH: 200,
      EMPTYBOXHEIGHT: 50,
      BOXWIDTH: 85,
      BOXHEIGHT: 100,
      YAXISSTEPHEIGHT: 10,
      CIRCLEDIAMETER: 7,
      CIRCLEOFFSET: 10,
      COAG_COLOR: "#00AEEF",
      CUT_COLOR: "#FFD700",
      APC_COLOR: "#67C18C",
      ODDS: [
        0.1, 0.3, 0.5, 0.7, 0.9, 1.1, 1.3, 1.5, 1.7, 1.9, 2.1, 2.3, 2.5, 2.7,
        2.9, 3.1, 3.3, 3.5, 3.7, 3.9, 4.1, 4.3, 4.5, 4.7, 4.9, 5.1, 5.3, 5.5,
        5.7, 5.9, 6.1, 6.3, 6.5, 6.7, 6.9, 7.1, 7.3, 7.5, 7.7, 7.9, 8.1, 8.3,
        8.5, 8.7, 8.9, 9.1, 9.3, 9.5, 9.7, 9.9,
      ],
      EVENS: [
        0.2, 0.4, 0.6, 0.8, 1.0, 1.2, 1.4, 1.6, 1.8, 2.0, 2.2, 2.4, 2.6, 2.8,
        3.0, 3.2, 3.4, 3.6, 3.8, 4.0, 4.2, 4.4, 4.6, 4.8, 5.0, 5.2, 5.4, 5.6,
        5.8, 6.0, 6.2, 6.4, 6.6, 6.8, 7.0, 7.2, 7.4, 7.6, 7.8, 8.0, 8.2, 8.4,
        8.6, 8.8, 9.0, 9.2, 9.4, 9.6, 9.8, 10.0,
      ],
      SOLID_COLOR: "",
    };
  },
  computed: {
    HEIGHT() {
      return this.svgHeight;
    },
  },
  methods: {
    setupGraph(mode, data, isShowAll) {
      var svg = document.getElementById("svgMatrixGraph");
      svg.innerHTML = "";
      svg.setAttribute("width", this.WIDTH);
      svg.setAttribute("height", this.HEIGHT);
      var filter_data = [];
      switch (mode) {
        case "CUT":
          this.SOLID_COLOR = this.CUT_COLOR;
          break;
        case "APC":
          this.SOLID_COLOR = this.APC_COLOR;
          break;
        case "COAG":
        default:
          this.SOLID_COLOR = this.COAG_COLOR;
          break;
      }

      if (isShowAll == true) {
        filter_data = data;
        this.svgHeight = `${filter_data.length - 1}00`;
      } else {
        filter_data = data.filter((d) => d.Total > 0);
        this.svgHeight = `${filter_data.length + 1}00`;
      }
      var yLineHeight = 0;
      var xLineWidth = 0;
      for (var i = 0; i < filter_data.length; i++) {
        if (filter_data[i].Total > 0) yLineHeight += this.BOXHEIGHT;
        else yLineHeight += this.EMPTYBOXHEIGHT;
      }

      xLineWidth = this.YAXISWIDTH + this.BOXWIDTH * 10;
      for (i = 0; i <= 10; i++) {
        svg.appendChild(
          this.GetYAxisLine(
            this.YAXISWIDTH + i * this.BOXWIDTH,
            0,
            this.YAXISWIDTH + i * this.BOXWIDTH,
            yLineHeight + this.YAXISSTEPHEIGHT,
          ),
        );
        if (i < 10) {
          for (var j = 1; j < 5; j++) {
            var xStepAxis =
              this.YAXISWIDTH + i * this.BOXWIDTH + j * (this.BOXWIDTH / 5);
            svg.appendChild(
              this.GetYAxisLine(
                xStepAxis,
                yLineHeight,
                xStepAxis,
                yLineHeight + this.YAXISSTEPHEIGHT,
              ),
            );
          }
        }
        if (i == 0) {
          svg.appendChild(
            this.GetXScaleText(
              this.YAXISWIDTH + i * this.BOXWIDTH - 10,
              yLineHeight + this.YAXISSTEPHEIGHT + 15,
              "0.1",
            ),
          );
        } else {
          svg.appendChild(
            this.GetXScaleText(
              this.YAXISWIDTH + i * this.BOXWIDTH - 10,
              yLineHeight + this.YAXISSTEPHEIGHT + 15,
              i.toString() + ".0",
            ),
          );
        }
      }
      let currentHeight = 0;
      for (i = 0; i < filter_data.length; i++) {
        if (filter_data[i].Total > 0) {
          svg.appendChild(
            this.GetXAxisLine(
              0,
              this.BOXHEIGHT + currentHeight,
              xLineWidth,
              this.BOXHEIGHT + currentHeight,
              1,
            ),
          );
          svg.appendChild(
            this.GetYScaleText(
              0,
              this.BOXHEIGHT + currentHeight - 10,
              filter_data[i].Mode,
              1,
            ),
          );
          for (var k = 0; k < this.ODDS.length; k++) {
            var ct = 0;
            var s1 = filter_data[i].Chart.filter((f) => f.x == this.ODDS[k]);
            var s2 = filter_data[i].Chart.filter((f) => f.x == this.EVENS[k]);
            if (s1.length > 0) ct += s1[0].y;
            if (s2.length > 0) ct += s2[0].y;
            if (ct > 5) ct = 5;

            if (ct > 0) {
              var r = (this.ODDS[k] * 10) % 10;
              var d = Math.floor((this.ODDS[k] * 10) / 10);
              var p = Math.floor(r / 2) + (r % 2);

              var circleXAxis =
                this.YAXISWIDTH +
                d * this.BOXWIDTH +
                p * (this.BOXWIDTH / 5) -
                this.CIRCLEOFFSET;
              for (var m = 0; m < ct; m++) {
                svg.appendChild(
                  this.GetCircle(
                    circleXAxis,
                    this.BOXHEIGHT +
                      currentHeight -
                      10 -
                      ((this.BOXHEIGHT - 10) / 5) * m,
                    filter_data[i].Mode,
                    this.ODDS[k],
                  ),
                );
              }
            }
          }
          currentHeight += this.BOXHEIGHT;
        } else {
          svg.appendChild(
            this.GetXAxisLine(
              0,
              this.EMPTYBOXHEIGHT + currentHeight,
              xLineWidth,
              this.EMPTYBOXHEIGHT + currentHeight,
              0.5,
            ),
          );
          svg.appendChild(
            this.GetYScaleText(
              0,
              this.EMPTYBOXHEIGHT + currentHeight - 10,
              filter_data[i].Mode,
              0.5,
            ),
          );
          currentHeight += this.EMPTYBOXHEIGHT;
        }
      }
    },

    GetYAxisLine(x1, y1, x2, y2) {
      var line = document.createElementNS("http://www.w3.org/2000/svg", "line");
      line.setAttribute("x1", x1);
      line.setAttribute("y1", y1);
      line.setAttribute("x2", x2);
      line.setAttribute("y2", y2);
      line.setAttribute("stroke", "white");
      line.setAttribute("stroke-width", 1);
      return line;
    },
    GetXAxisLine(x1, y1, x2, y2, o) {
      var line = document.createElementNS("http://www.w3.org/2000/svg", "line");
      line.setAttribute("x1", x1);
      line.setAttribute("y1", y1);
      line.setAttribute("x2", x2);
      line.setAttribute("y2", y2);
      line.setAttribute("stroke", this.SOLID_COLOR);
      line.setAttribute("stroke-width", 1);
      line.setAttribute("stroke-opacity", o);
      return line;
    },
    GetXScaleText(x, y, t) {
      var text = document.createElementNS("http://www.w3.org/2000/svg", "text");
      text.setAttribute("x", x);
      text.setAttribute("y", y);
      text.setAttribute("font-size", 13);
      text.setAttribute("fill", "white");
      text.innerHTML = t;
      return text;
    },
    GetYScaleText(x, y, t, o) {
      var text = document.createElementNS("http://www.w3.org/2000/svg", "text");
      text.setAttribute("x", x);
      text.setAttribute("y", y);
      text.setAttribute("font-size", 20);
      text.setAttribute("fill", this.SOLID_COLOR);
      text.setAttribute("fill-opacity", o);
      text.innerHTML = t;
      return text;
    },
    GetCircle(x, y, m, e) {
      var self = this;
      var circle = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "circle",
      );
      circle.setAttribute("cx", x);
      circle.setAttribute("cy", y);
      circle.setAttribute("r", this.CIRCLEDIAMETER);
      circle.setAttribute("fill", this.SOLID_COLOR);
      circle.setAttribute("mode", m);
      circle.setAttribute("effect", e);
      circle.setAttribute("cursor", "pointer");
      circle.classList.add("dot-circle");
      circle.onclick = function () {
        self.$emit(
          "circleClick",
          circle.getAttribute("effect"),
          circle.getAttribute("mode"),
        );
      };
      return circle;
    },
  },
  mounted() {
    this.setupGraph(
      this.selectedMode,
      this.matrixChartData.matrixData,
      this.isShowAll,
    );
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss";

.chart {
  font-family: $font_nettoRegular;
  background-color: #19222b;
  display: flex;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 80px;
  width: fit-content;
}
</style>
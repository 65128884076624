<template>
  <div class="scrollable-video-segment">
    <VideoCard v-for="video in videos" :key="video.VideoId" :video="video" />
  </div>
</template>

<script>
import VideoCard from "./VideoCard.vue";
export default {
  components: {
    VideoCard,
  },
  props: {
    videos: {
      type: Array,
      require: true,
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss";

.scrollable-video-segment {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  @include breakpoint(xs) {
    justify-content: center;
  }

  @include breakpoint(s) {
    justify-content: center;
  }
}
</style>
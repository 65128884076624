<template>
  <CustomModal
    :modalOpen="modalOpen"
    @toggleModal="toggleModal()"
  ></CustomModal>
</template>

<script>
import CustomModal from "../CustomModal.vue";
export default {
  components: {
    CustomModal,
  },
  props: {
    modalOpen: {
      type: Boolean,
      require: true,
    },
  },
  methods: {
    toggleModal() {
      this.$emit("toggleModal");
    },
  },
};
</script>

<style>
</style>
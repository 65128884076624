<template>
  <div class="multimedia-grid-item" :style="bgColor">
    <a :href="href" target="_blank">
      <span v-html="subheadline" class="multimedia-grid-item-subheadline" />
      <h3 v-html="headline" class="multimedia-grid-item-headline" />
      <img class="multimedia-grid-item-icon" :src="src" />
    </a>
  </div>
</template>

<script>
export default {
  props: {
    headline: String,
    subheadline: String,
    href: String,
    src: String,
    backgroundColor: String,
  },
  computed: {
    bgColor() {
      return {
        "background-color": this.backgroundColor,
      };
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss";

.copyrightSup {
  font-family: inherit;
  font-weight: 50;
  position: relative;
  top: -0.5em;
  font-size: 60%;
}

.multimedia-grid-item {
  position: relative;

  a {
    display: block;
    padding: 3rem 1.5rem;
  }

  &-headline {
    @include ts-grid-headline();
  }

  &-subheadline {
    @include ts-grid-subheadline();
  }

  &-icon {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
}
</style>
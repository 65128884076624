<template>
  <div>
    <div class="chart">
      <svg
        id="svgEndoCutGraph"
        height="400"
        width="600"
        version="1.2"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        class="graph"
        aria-labelledby="title"
        role="img"
      >
        <g class="grid x-grid" id="xGrid">
          <line
            id="xAxis"
            class="axis-line"
            x1="50"
            x2="50"
            y1="15"
            y2="485"
          ></line>
        </g>
        <g class="grid y-grid" id="yGrid">
          <line
            id="yAxis"
            class="axis-line"
            x1="50"
            x2="700"
            y1="485"
            y2="485"
          ></line>
        </g>
        <g class="labels x-labels">
          <text id="xLabel" x="715" y="490" class="label-axis">Time</text>
        </g>
        <g class="labels y-labels">
          <text id="yLabel" x="40" y="10" class="label-axis">Voltage</text>
        </g>
        <g>
          <rect
            id="bluebar"
            class="bluebar"
            x="52"
            width="648"
            y="350"
            height="132"
          ></rect>
        </g>
        <g>
          <rect id="bar11" class="yellowbar1"></rect>
          <rect id="bar12" class="yellowbar2"></rect>
        </g>
        <g>
          <rect id="bar21" class="yellowbar1"></rect>
          <rect id="bar22" class="yellowbar2"></rect>
        </g>
        <g>
          <rect id="bar31" class="yellowbar1"></rect>
          <rect id="bar32" class="yellowbar2"></rect>
        </g>
        <g>
          <rect id="bar41" class="yellowbar1"></rect>
          <rect id="bar42" class="yellowbar2"></rect>
        </g>
        <g>
          <rect id="bracket_e_top" class="linebar"></rect>
          <rect id="bracket_e_line" class="linebar"></rect>
          <rect id="bracket_e_bottom" class="linebar"></rect>
          <rect id="bracket_e_btn" rx="8" />
          <text
            id="bracket_e_prevbtn"
            x="0"
            y="15"
            class="fas fa-arrow-left"
            @click="EffectPrev()"
          >
            &#xf060;
          </text>
          <text id="bracket_e_value" class="label-title"></text>
          <text
            id="bracket_e_nextbtn"
            x="0"
            y="15"
            class="fas fa-arrow-right"
            @click="EffectNext()"
          >
            &#xf061;
          </text>
          <text id="bracket_e_legend" class="label-title">effect (E)</text>
        </g>
        <g>
          <rect id="bracket_d_left" class="linebar"></rect>
          <rect id="bracket_d_line" class="linebar"></rect>
          <rect id="bracket_d_right" class="linebar"></rect>
          <rect id="bracket_d_btn" rx="8" />
          <text
            id="bracket_d_prevbtn"
            x="0"
            y="15"
            class="fas fa-arrow-left"
            @click="DurationPrev()"
          >
            &#xf060;
          </text>
          <text id="bracket_d_value" class="label-title"></text>
          <text
            id="bracket_d_nextbtn"
            x="0"
            y="15"
            class="fas fa-arrow-right"
            @click="DurationNext()"
          >
            &#xf061;
          </text>
          <text id="bracket_d_legend" class="label-title">
            cutting duration (D)
          </text>
        </g>
        <g>
          <rect id="bracket_i_left" class="linebar"></rect>
          <rect id="bracket_i_line" class="linebar"></rect>
          <rect id="bracket_i_right" class="linebar"></rect>
          <rect id="bracket_i_btn" rx="8" />
          <text
            id="bracket_i_prevbtn"
            x="0"
            y="15"
            class="fas fa-arrow-left"
            @click="IntervalPrev()"
          >
            &#xf060;
          </text>
          <text id="bracket_i_value" class="label-title"></text>
          <text
            id="bracket_i_nextbtn"
            x="0"
            y="15"
            class="fas fa-arrow-right"
            @click="IntervalNext()"
          >
            &#xf061;
          </text>
          <text id="bracket_i_legend" class="label-title">interval (I)</text>
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    discipline: {
      type: String,
      require: true,
    },
    intervention: {
      type: String,
      require: true,
    },
    selectedMode: {
      type: String,
      require: true,
    },
  },
  computed: {
    mode: {
      get() {
        return this.selectedMode;
      },
      set(newValue) {
        return newValue;
      },
    },
    effect: {
      get() {
        return this.selected_E;
      },
      set(newValue) {
        return newValue;
      },
    },
    duration: {
      get() {
        return this.selected_D;
      },
      set(newValue) {
        return newValue;
      },
    },
    interval: {
      get() {
        return this.selected_I;
      },
      set(newValue) {
        return newValue;
      },
    },
    buttonData() {
      return this.$store.state.charts.barChartButtons;
    },
  },
  data() {
    return {
      WIDTH: 1000,
      HEIGHT: 600,

      WIDTH_OFFSET: 20,
      HEIGHT_OFFSET: 20,

      BAR_E_HEIGHT_MAX: 300,
      BAR_D_WIDTH_MAX: 80,

      BAR_STEP_HEIGHT_MAX: 450,
      BAR_STEP_HEIGHT_MID: 400,
      BAR_STEP_HEIGHT_MIN: 350,

      MAJOR_STEP_WIDTH: 270,

      MAX_E: 4,
      MAX_I: 10,
      MAX_D: 4,

      AXIS_THICKNESS: 2,
      BRACKET_THICKNESS: 2,
      BRACKET_HEIGHT: 20,

      BRACKET_E_OFFSET: 20,
      BRACKET_D_OFFSET: 50,
      BRACKET_I_OFFSET: 15,

      selected_E: 4,
      selected_D: 4,
      selected_I: 10,

      m: 1,
      e: 2,
      d: 3,
      i: 4,
    };
  },
  watch: {
    selectedMode() {
      this.SetGraph(
        this.selectedMode,
        this.selected_E,
        this.selected_D,
        this.selected_I,
      );
      this.$store.dispatch("loadBarChartTopVideos", {
        discipline: this.discipline,
        intervention: this.intervention,
        mode: this.selectedMode,
      });
    },
  },
  mounted() {
    this.SetGraph(
      this.selectedMode,
      this.selected_E,
      this.selected_D,
      this.selected_I,
    );
    this.$store.dispatch("loadBarChartTopVideos", {
      discipline: this.discipline,
      intervention: this.intervention,
      mode: this.selectedMode,
    });
  },
  methods: {
    SetGraph(m, e, d, i) {
      this.m = m;
      if (e >= this.MAX_E) this.e = this.MAX_E;
      else if (e <= 1) this.e = 1;
      else this.e = e;

      if (d >= this.MAX_D) this.d = this.MAX_D;
      else if (d <= 1) this.d = 1;
      else this.d = d;

      if (i >= this.MAX_I) this.i = this.MAX_I;
      else if (i <= 1) this.i = 1;
      else this.i = i;
      if (this.e == this.MAX_E) {
        this.HideElement("bracket_e_nextbtn");
      } else {
        this.ShowElement("bracket_e_nextbtn");
      }

      if (this.d == this.MAX_D) this.HideElement("bracket_d_nextbtn");
      else this.ShowElement("bracket_d_nextbtn");

      if (this.i == this.MAX_I) this.HideElement("bracket_i_nextbtn");
      else this.ShowElement("bracket_i_nextbtn");

      if (this.e == 1) {
        this.HideElement("bracket_e_prevbtn");
        this.ShowElement("bracket_e_prevbtn");
      }

      if (this.d == 1) this.HideElement("bracket_d_prevbtn");
      else this.ShowElement("bracket_d_prevbtn");

      if (this.i == 1) this.HideElement("bracket_i_prevbtn");
      else this.ShowElement("bracket_i_prevbtn");

      var _CutDurationWidth = 10 + (this.BAR_D_WIDTH_MAX / 15) * this.d;
      var _IntervalWidth = this.MAJOR_STEP_WIDTH - (10 - this.i) * 5;

      this.SetSize("svgEndoCutGraph", this.WIDTH, this.HEIGHT);
      this.SetAxis(
        "xAxis",
        0,
        this.HEIGHT - this.HEIGHT_OFFSET,
        this.WIDTH - this.WIDTH_OFFSET - 10,
        this.HEIGHT - this.HEIGHT_OFFSET,
      );
      this.SetAxis(
        "yAxis",
        this.WIDTH_OFFSET,
        this.HEIGHT_OFFSET,
        this.WIDTH_OFFSET,
        this.HEIGHT,
      );
      this.SetElementXY("yLabel", this.WIDTH_OFFSET, this.HEIGHT_OFFSET - 10);
      this.SetElementXY(
        "xLabel",
        this.WIDTH - this.WIDTH_OFFSET - 10 + 2,
        this.HEIGHT - this.HEIGHT_OFFSET,
      );

      var _BlueBarHeight = 0;
      if (this.e <= 1) {
        this.SetRect(
          "bluebar",
          this.WIDTH_OFFSET + this.AXIS_THICKNESS,
          this.HEIGHT_OFFSET,
          0,
          0,
        );
      } else if (this.e == 2) {
        _BlueBarHeight = this.BAR_E_HEIGHT_MAX / 2;
        this.SetRect(
          "bluebar",
          this.WIDTH_OFFSET + this.AXIS_THICKNESS,
          this.HEIGHT - this.HEIGHT_OFFSET - this.BAR_E_HEIGHT_MAX / 2,
          _IntervalWidth * 3 + this.BAR_D_WIDTH_MAX,
          this.BAR_E_HEIGHT_MAX / 2,
        );
      } else if (this.e > 2) {
        _BlueBarHeight = this.BAR_E_HEIGHT_MAX;
        this.SetRect(
          "bluebar",
          this.WIDTH_OFFSET + this.AXIS_THICKNESS,
          this.HEIGHT - this.HEIGHT_OFFSET - this.BAR_E_HEIGHT_MAX,
          _IntervalWidth * 3 + this.BAR_D_WIDTH_MAX,
          this.BAR_E_HEIGHT_MAX,
        );
      }

      var _BarHeights = [];
      if (this.m == "endoCUT I") {
        _BarHeights.push(this.BAR_STEP_HEIGHT_MID);
        _BarHeights.push(this.BAR_STEP_HEIGHT_MID);
        _BarHeights.push(this.BAR_STEP_HEIGHT_MID);
        _BarHeights.push(this.BAR_STEP_HEIGHT_MID);
      } else if (this.m == "endoCUT Q") {
        _BarHeights.push(this.BAR_STEP_HEIGHT_MIN);
        _BarHeights.push(this.BAR_STEP_HEIGHT_MID);
        _BarHeights.push(this.BAR_STEP_HEIGHT_MAX);
        _BarHeights.push(this.BAR_STEP_HEIGHT_MAX);
      }

      for (i = 1; i <= 4; i++) {
        var barId1 = "bar" + i + "1";
        var barId2 = "bar" + i + "2";
        var x_pos =
          this.WIDTH_OFFSET + this.AXIS_THICKNESS + (i - 1) * _IntervalWidth;
        var x_wid = this.BAR_D_WIDTH_MAX - _CutDurationWidth;
        this.SetRect(
          barId1,
          x_pos,
          this.HEIGHT - this.HEIGHT_OFFSET - _BarHeights[i - 1],
          x_wid,
          _BarHeights[i - 1],
        );
        this.SetRect(
          barId2,
          x_pos + x_wid,
          this.HEIGHT - this.HEIGHT_OFFSET - _BarHeights[i - 1],
          _CutDurationWidth,
          _BarHeights[i - 1],
        );
      }
      var bracket_e_x =
        this.WIDTH_OFFSET +
        this.AXIS_THICKNESS +
        _IntervalWidth +
        this.BRACKET_E_OFFSET +
        this.BAR_D_WIDTH_MAX;
      if (this.e <= 1) {
        this.SetRect(
          "bracket_e_top",
          bracket_e_x - this.BRACKET_HEIGHT / 2,
          this.HEIGHT - this.HEIGHT_OFFSET - _BlueBarHeight,
          0,
          0,
        );
        this.SetRect(
          "bracket_e_bottom",
          bracket_e_x - this.BRACKET_HEIGHT / 2,
          this.HEIGHT - this.HEIGHT_OFFSET - 2,
          0,
          0,
        );
        this.SetRect(
          "bracket_e_line",
          bracket_e_x,
          this.HEIGHT - this.HEIGHT_OFFSET - _BlueBarHeight,
          0,
          0,
        );
        this.SetRect(
          "bracket_e_btn",
          bracket_e_x + 20,
          this.HEIGHT - this.HEIGHT_OFFSET - 55,
          80,
          20,
        );
        this.SetElementXY(
          "bracket_e_prevbtn",
          bracket_e_x + 24,
          this.HEIGHT - this.HEIGHT_OFFSET - 40,
        );
        this.SetElementXY(
          "bracket_e_value",
          bracket_e_x + 54,
          this.HEIGHT - this.HEIGHT_OFFSET - 40,
        );
        this.SetElementXY(
          "bracket_e_nextbtn",
          bracket_e_x + 84,
          this.HEIGHT - this.HEIGHT_OFFSET - 40,
        );
        this.SetElementXY(
          "bracket_e_legend",
          bracket_e_x + 24,
          this.HEIGHT - this.HEIGHT_OFFSET - 15,
        );
        this.SetValue("bracket_e_value", this.e);
      } else {
        this.SetRect(
          "bracket_e_top",
          bracket_e_x - this.BRACKET_HEIGHT / 2,
          this.HEIGHT - this.HEIGHT_OFFSET - _BlueBarHeight,
          this.BRACKET_HEIGHT,
          this.BRACKET_THICKNESS,
        );
        this.SetRect(
          "bracket_e_bottom",
          bracket_e_x - this.BRACKET_HEIGHT / 2,
          this.HEIGHT - this.HEIGHT_OFFSET - 2,
          this.BRACKET_HEIGHT,
          this.BRACKET_THICKNESS,
        );
        this.SetRect(
          "bracket_e_line",
          bracket_e_x,
          this.HEIGHT - this.HEIGHT_OFFSET - _BlueBarHeight,
          this.BRACKET_THICKNESS,
          _BlueBarHeight,
        );
        this.SetRect(
          "bracket_e_btn",
          bracket_e_x + 20,
          this.HEIGHT - this.HEIGHT_OFFSET - _BlueBarHeight + 20,
          80,
          20,
        );
        this.SetElementXY(
          "bracket_e_prevbtn",
          bracket_e_x + 24,
          this.HEIGHT - this.HEIGHT_OFFSET - _BlueBarHeight + 35,
        );
        this.SetElementXY(
          "bracket_e_value",
          bracket_e_x + 54,
          this.HEIGHT - this.HEIGHT_OFFSET - _BlueBarHeight + 35,
        );
        this.SetElementXY(
          "bracket_e_nextbtn",
          bracket_e_x + 84,
          this.HEIGHT - this.HEIGHT_OFFSET - _BlueBarHeight + 35,
        );
        this.SetElementXY(
          "bracket_e_legend",
          bracket_e_x + 24,
          this.HEIGHT - this.HEIGHT_OFFSET - _BlueBarHeight + 55,
        );
        this.SetValue("bracket_e_value", this.e);
      }

      var bracket_d_x =
        this.WIDTH_OFFSET +
        this.AXIS_THICKNESS +
        _IntervalWidth * 2 +
        this.BAR_D_WIDTH_MAX -
        _CutDurationWidth;
      var bracket_d_y =
        this.HEIGHT -
        this.HEIGHT_OFFSET -
        _BlueBarHeight -
        this.BRACKET_D_OFFSET;
      this.SetRect(
        "bracket_d_left",
        bracket_d_x,
        bracket_d_y - this.BRACKET_HEIGHT / 2,
        this.BRACKET_THICKNESS,
        this.BRACKET_HEIGHT,
      );
      this.SetRect(
        "bracket_d_right",
        bracket_d_x + _CutDurationWidth - this.BRACKET_THICKNESS,
        bracket_d_y - this.BRACKET_HEIGHT / 2,
        this.BRACKET_THICKNESS,
        this.BRACKET_HEIGHT,
      );
      this.SetRect(
        "bracket_d_line",
        bracket_d_x,
        bracket_d_y,
        _CutDurationWidth,
        this.BRACKET_THICKNESS,
      );
      this.SetRect(
        "bracket_d_btn",
        bracket_d_x + _CutDurationWidth + 20,
        bracket_d_y - 20,
        80,
        20,
      );
      this.SetElementXY(
        "bracket_d_prevbtn",
        bracket_d_x + _CutDurationWidth + 24,
        bracket_d_y - 5,
      );
      this.SetElementXY(
        "bracket_d_value",
        bracket_d_x + _CutDurationWidth + 54,
        bracket_d_y - 5,
      );
      this.SetElementXY(
        "bracket_d_nextbtn",
        bracket_d_x + _CutDurationWidth + 84,
        bracket_d_y - 5,
      );
      this.SetElementXY(
        "bracket_d_legend",
        bracket_d_x + _CutDurationWidth + 24,
        bracket_d_y + 15,
      );
      this.SetValue("bracket_d_value", this.d);

      var bracket_i_x =
        this.WIDTH_OFFSET + this.AXIS_THICKNESS + _IntervalWidth * 2;
      var bracket_i_y =
        this.HEIGHT -
        this.HEIGHT_OFFSET -
        _BarHeights[3] -
        this.BRACKET_I_OFFSET;
      this.SetRect(
        "bracket_i_left",
        bracket_i_x,
        bracket_i_y - this.BRACKET_HEIGHT / 2,
        this.BRACKET_THICKNESS,
        this.BRACKET_HEIGHT,
      );
      this.SetRect(
        "bracket_i_right",
        bracket_i_x + _IntervalWidth,
        bracket_i_y - this.BRACKET_HEIGHT / 2,
        this.BRACKET_THICKNESS,
        this.BRACKET_HEIGHT,
      );
      this.SetRect(
        "bracket_i_line",
        bracket_i_x,
        bracket_i_y,
        _IntervalWidth,
        this.BRACKET_THICKNESS,
      );
      this.SetRect("bracket_i_btn", bracket_i_x, bracket_i_y - 45, 80, 20);
      this.SetElementXY("bracket_i_prevbtn", bracket_i_x + 4, bracket_i_y - 30);
      this.SetElementXY("bracket_i_value", bracket_i_x + 34, bracket_i_y - 30);
      this.SetElementXY(
        "bracket_i_nextbtn",
        bracket_i_x + 64,
        bracket_i_y - 30,
      );
      this.SetElementXY("bracket_i_legend", bracket_i_x + 4, bracket_i_y - 10);
      this.SetValue("bracket_i_value", this.i);
    },
    SetSize(element, width, height) {
      document.getElementById(element).setAttribute("width", width);
      document.getElementById(element).setAttribute("height", height);
    },

    SetRect(element, x, y, w, h) {
      document.getElementById(element).setAttribute("x", x);
      document.getElementById(element).setAttribute("y", y);
      this.SetSize(element, w, h);
    },

    SetAxis(axis, x1, y1, x2, y2) {
      document.getElementById(axis).setAttribute("x1", x1);
      document.getElementById(axis).setAttribute("x2", x2);
      document.getElementById(axis).setAttribute("y1", y1);
      document.getElementById(axis).setAttribute("y2", y2);
    },

    SetElementXY(element, x, y) {
      document.getElementById(element).setAttribute("x", x);
      document.getElementById(element).setAttribute("y", y);
    },

    SetValue(element, value) {
      document.getElementById(element).textContent = value.toString();
    },

    ShowElement(element) {
      document.getElementById(element).style.visibility = "";
    },

    HideElement(element) {
      document.getElementById(element).style.visibility = "hidden";
    },

    EffectPrev() {
      this.SetGraph(this.m, this.e - 1, this.d, this.i);
      this.$emit("barChartChange", this.e, this.d, this.i);
    },

    EffectNext() {
      this.SetGraph(this.m, this.e + 1, this.d, this.i);
      this.$emit("barChartChange", this.e, this.d, this.i);
    },

    DurationPrev() {
      this.SetGraph(this.m, this.e, this.d - 1, this.i);
      this.$emit("barChartChange", this.e, this.d, this.i);
    },

    DurationNext() {
      this.SetGraph(this.m, this.e, this.d + 1, this.i);
      this.$emit("barChartChange", this.e, this.d, this.i);
    },

    IntervalPrev() {
      this.SetGraph(this.m, this.e, this.d, this.i - 1);
      this.$emit("barChartChange", this.e, this.d, this.i);
    },

    IntervalNext() {
      this.SetGraph(this.m, this.e, this.d, this.i + 1);
      this.$emit("barChartChange", this.e, this.d, this.i);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss";
.chart {
  font-family: $font_nettoRegular;
  background-color: #333023;
  display: flex;
  justify-content: center;
  width: fit-content;
  margin: 0 auto;
}

#bracket_e_btn {
  fill: #008ed6;
  color: white;
  font-weight: bold;
}

#bracket_e_prevbtn {
  cursor: pointer;
  fill: white;
}

#bracket_e_nextbtn {
  cursor: pointer;
  fill: white;
}

#bracket_d_btn {
  fill: #ffd700;
}

#bracket_d_prevbtn {
  cursor: pointer;
  fill: white;
}

#bracket_d_nextbtn {
  cursor: pointer;
  fill: white;
}

#bracket_i_btn {
  fill: #ffd700;
}

#bracket_i_prevbtn {
  cursor: pointer;
  fill: white;
}

#bracket_i_nextbtn {
  cursor: pointer;
  fill: white;
}

.graph .labels.x-labels {
  text-anchor: start;
}

.graph .labels.y-labels {
  text-anchor: start;
}

.graph {
  fill: black;
}

.graph .grid {
  stroke: #ccc;
  stroke-dasharray: 0;
  stroke-width: 1;
}

.labels {
  font-size: 13px;
  stroke: #ccc;
}

.label-title {
  font-weight: bold;
  font-size: 14px;
  color: white;
  fill: white;
}
.label-axis {
  font-weight: bold;
  font-size: 12px;
  color: #ccc;
  fill: #ccc;
}
.label-subtitle {
  font-weight: bold;
  font-size: 12px;
  color: #ccc;
  fill: #ccc;
}
.data {
  fill: red;
  stroke-width: 1;
}
.axis-line {
  stroke-width: 2px;
}
.bluebar {
  fill: #00aeef;
  stroke-width: 0.2;
}
.yellowbar1 {
  fill: #ffeb80;
  stroke-width: 0.2;
}
.yellowbar2 {
  /* fill: gold; */
  fill: #ffd700;
  stroke-width: 0.2;
}
.linebar {
  fill: white;
  stroke-width: 0.5;
}
.dot-circle:hover {
  stroke-width: 3;
  stroke: white;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
  justify-content: center;
}

.top-button {
  background-color: rgb(255, 215, 0);
  width: 220px;
  height: 45px;
  border: none;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
}
</style>